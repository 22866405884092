import * as React from 'react';
const IconDone = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      fill="#396DFF"
      d="m14.658 4.899-8.165 7.775a1.173 1.173 0 0 1-.379.241 1.217 1.217 0 0 1-1.273-.241L1.342 9.34a1.11 1.11 0 0 1-.253-.36 1.066 1.066 0 0 1 .253-1.212 1.219 1.219 0 0 1 1.273-.241c.142.055.27.137.379.24l2.673 2.549 7.34-6.991c.218-.209.515-.326.825-.326.31 0 .607.117.826.326.219.208.342.491.342.786 0 .295-.123.578-.342.787Z"
    />
  </svg>
);
export default IconDone;
