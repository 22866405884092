export const directionListValue = [
  {
    id: 'therapeuticDentistry',
    name_ua: 'Терапевтична стоматологія',
    description:
      'Терапевтична стоматологія — це галузь стоматології, спрямована на діагностику та лікування захворювань зубів та їхніх тканин. Лікарі-терапевти стоматологи займаються видаленням карієсу, лікуванням пульпіту, а також відновленням та профілактикою захворювань порожнини рота.',
  },
  {
    id: 'orthodontics',
    name_ua: 'Ортодонтія',
    description:
      'Ортодонтія — це галузь стоматології, що спеціалізується на діагностиці, профілактиці та корекції аномалій розвитку зубно-челюстної системи, таких як неправильне положення зубів та неспіввідношення щелеп. Ортодонтічні лікарі використовують різноманітні методи, включаючи брекет-системи, ретейнери та брекет-плити, для досягнення оптимального функціонального та естетичного вирішення проблем зубно-челюстної системи пацієнта.',
  },
  {
    id: 'surgicalDentistry',
    name_ua: 'Хірургічна стоматологія',
    description:
      'Хірургічна стоматологія - це галузь стоматології, спрямована на діагностику та лікування хірургічних захворювань порожнини рота та обличчя, включаючи видалення зубів, хірургічне втручання при аномаліях щелепно-лицьової області та імплантацію зубних імплантатів для відновлення функцій та естетики. Спеціалісти цієї галузі займаються також лікуванням травм, абсцесів та інших патологічних станів, використовуючи сучасні методи і технології.',
  },
  {
    id: 'prosthesis',
    name_ua: 'Протезування',
    description:
      'Протезування в стоматології - це галузь, що займається відновленням втрачених зубів за допомогою зубних протезів, таких як коронки, мости, імплантати або съемні протези, з метою відновлення функціональності та естетики зубного ряду пацієнта. Протези дозволяють пацієнтам повернутися до нормального способу життя, забезпечуючи комфорт при жуванні та вдосконалення зовнішнього вигляду усмішки.',
  },
  {
    id: 'endodontics',
    name_ua: 'Ендодонтія',
    description:
      "Ендодонтія є галуззю стоматології, спрямованою на лікування захворювань зубів, які вражені за межами зубної порожнини, зокрема в кореневому каналі. Спеціалісти ендодонтії використовують спеціалізовані методи, такі як кореневе лікування, для збереження і відновлення функціональності та здоров'я зубів.",
  },
  {
    id: 'parandotology',
    name_ua: 'Парандотологія',
    description:
      "Парадонтологія є галуззю стоматології, що спеціалізується на вивченні та лікуванні захворювань пародонту, який включає в себе ясна, кістки, що оточують зуби, та зубні зв'язки. Спеціалісти у цій галузі займаються діагностикою та лікуванням таких проблем, як гінгівіт, пародонтит та інші захворювання, що впливають на здоров'я тканин, які підтримують зуби.",
  },
  {
    id: 'maxillofacialSurgery',
    name_ua: 'Щелепно-лицьова хірургія',
    description:
      'Щелепно-лицьова хірургія в стоматології спеціалізується на діагностиці та лікуванні захворювань та вад кісток, суглобів та тканин обличчя та щелеп. Ця галузь також включає в себе хірургічні процедури для виправлення дефектів, реконструкції обличчя та забезпечення оптимального функціонального і естетичного стану щелепно-лицьової області.',
  },
  {
    id: 'childrensDentistry',
    name_ua: 'Дитяча стоматологія',
    description:
      'Дитяча стоматологія спеціалізується на наданні комплексної та індивідуалізованої стоматологічної допомоги дітям різного віку, враховуючи особливості їхнього фізичного та психологічного розвитку. Цей напрям фокусується не лише на лікуванні захворювань, але й на профілактиці та формуванні правильних звичок у догляді за порожниною рота для забезпечення оптимального стану зубів та ясен у майбутньому.',
  },
  {
    id: 'aestheticDentistry',
    name_ua: 'Естетична стоматологія',
    description:
      'Естетична стоматологія — це спеціалізована галузь, що фокусується на поліпшенні зовнішнього вигляду зубів та усного порожнини через застосування сучасних методів і технологій, таких як відбілювання, керамічні відновлення та інші естетичні процедури, спрямовані на покращення усмішки та самопочуття пацієнтів.',
  },
  {
    id: 'radiology',
    name_ua: 'Рентгенологія',
    description:
      'Стоматологічна галузь "Рентгенологія" спеціалізується на застосуванні рентгенівських технік для діагностики та вивчення захворювань та стану зубів, щелеп, а також прилеглих тканин. Вона використовує рентгенівське випромінювання для отримання образів, що дозволяє лікарям точно визначити проблеми та розробляти ефективні плани лікування пацієнтів.',
  },
];
