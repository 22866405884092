import * as React from 'react';
const IconSocialMedia = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
  >
    <rect width={40} height={40} fill="#1244D0" rx={7} />
    <path
      fill="#fff"
      d="M23.304 16.696a1 1 0 0 0-1.414 1.414l1.414-1.414Zm-.707 8.496-.707-.707.707.707Zm-5.194 5.195.707.707-.707-.707Zm-7.79-7.79.707.707-.707-.707Zm3.305-1.89a1 1 0 0 0-1.415-1.414l1.415 1.414Zm3.778 2.597a1 1 0 0 0 1.414-1.414l-1.414 1.414Zm.707-8.496.707.707-.707-.708Zm5.194-5.195.707.707-.707-.707Zm7.79 0-.707.707.707-.707Zm0 7.79.707.707-.707-.707Zm-3.305 1.89a1 1 0 0 0 1.415 1.414l-1.415-1.414ZM21.89 18.11a4.508 4.508 0 0 1 1.32 3.188h2a6.508 6.508 0 0 0-1.906-4.602L21.89 18.11Zm1.32 3.188a4.508 4.508 0 0 1-1.32 3.187l1.414 1.415a6.508 6.508 0 0 0 1.907-4.602h-2Zm-1.32 3.187-5.194 5.195 1.414 1.414 5.194-5.194-1.414-1.415Zm-5.194 5.195a4.508 4.508 0 0 1-6.376 0l-1.414 1.414a6.508 6.508 0 0 0 9.204 0l-1.414-1.414Zm-6.376 0a4.508 4.508 0 0 1 0-6.376L8.906 21.89a6.508 6.508 0 0 0 0 9.204l1.414-1.414Zm0-6.376 2.598-2.597-1.415-1.414-2.597 2.597 1.414 1.414Zm7.79-1.414a4.508 4.508 0 0 1-1.32-3.188h-2c0 1.726.685 3.382 1.906 4.602l1.414-1.414Zm-1.32-3.188c0-1.195.475-2.342 1.32-3.187L16.696 14.1a6.508 6.508 0 0 0-1.906 4.602h2Zm1.32-3.187 5.194-5.195-1.414-1.414-5.194 5.194 1.414 1.415Zm5.194-5.195a4.508 4.508 0 0 1 6.376 0l1.414-1.414a6.508 6.508 0 0 0-9.204 0l1.414 1.414Zm6.376 0a4.508 4.508 0 0 1 0 6.376l1.414 1.414a6.508 6.508 0 0 0 0-9.204L29.68 10.32Zm0 6.376-2.598 2.597 1.415 1.414 2.597-2.597-1.414-1.414Z"
    />
  </svg>
);
export default IconSocialMedia;
