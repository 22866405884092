export const districtsListValue = [
  { id: 'location_1', name: 'Деснянський' },
  { id: 'location_2', name: 'Святошинський' },
  { id: 'location_3', name: 'Дніпровський' },
  { id: 'location_4', name: 'Печерський' },
  { id: 'location_5', name: 'Голосіївський' },
  { id: 'location_6', name: 'Дарницький' },
  { id: 'location_7', name: 'Солом’янський' },
  { id: 'location_8', name: 'Оболонський' },
  { id: 'location_9', name: 'Шевченківський' },
  { id: 'location_10', name: 'Подільський' },
];
