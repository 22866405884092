export const problemsListValue = [
  {
    id: 'category_1',
    category: 'Зубний біль',
    problems: [
      {
        id: 'acute_toothache',
        name_ua: 'Гострий зубний біль',
        name_en: 'Acute Toothache',
        description:
          'Гострий зубний біль - це болюче відчуття в зубах, яке може бути спричинене різними факторами, такими як карієс, захворювання ясен, чутливість зубів або інші стоматологічні проблеми. Ця проблема може викликати значний дискомфорт та обмежити якість життя пацієнта.',
        prevention:
          'Запобігання гострому зубному болю включає в себе регулярні візити до стоматолога для обстеження та профілактичного лікування, правильну гігієну ротової порожнини, відмову від шкідливих звичок, таких як куріння та зловживання алкоголем, а також здоровий спосіб життя та збалансовану дієту. Вчасна діагностика і лікування зубних захворювань також грають ключову роль у запобіганні гострого зубного болю.',
      },
      {
        id: 'pain_while_chewing',
        name_ua: 'Біль при жуванні/натисканні',
        name_en: 'Pain while Chewing/Biting',
        description:
          "Проблема полягає в болі, який виникає під час жування або натискання на щелепу, що може бути вказівником на різноманітні стани і порушення в системі травлення та структурі щелепи. Щоб запобігти цій проблемі, важливо звернутися до лікаря-стоматолога для професійного обстеження та діагностики, а також дотримуватися правильного режиму харчування і піклуватися про своє здоров'я порожнини рота.",
        prevention:
          'Запобігання цього болю може включати в себе обмеження вживання жорстких і твердих продуктів, регулярний огляд і чищення зубів, а також вчасну консультацію з лікарем при появі неприємних симптомів.',
      },
      {
        id: 'tooth_sensitivity',
        name_ua: 'Біль при термовпливі на зуб',
        name_en: 'Tooth Sensitivity (Hot/Cold)',
        description:
          'Проблема полягає в тому, що багато людей відчувають біль при впливі термічних факторів на свої зуби, особливо під час споживання гарячих або холодних напоїв.',
        prevention:
          'Для запобігання цій проблемі, важливо дотримуватися правильної гігієни порожнини рота та відвідувати регулярно стоматолога. Крім того, можна розглянути використання спеціальних зубних паст із мінералізуючими властивостями, які допоможуть зміцнити зубну емаль і зменшити чутливість до термічних подразників. Також варто обмежити споживання продуктів і напоїв з високим вмістом цукру та кислоти, оскільки це може призвести до погіршення стану зубів.',
      },
      {
        id: 'cavity_treatment',
        name_ua: 'Лікування карієсу',
        name_en: 'Cavity Treatment',
        description:
          'Проблема карієсу полягає в руйнуванні зубної емалі і утворенні чорних плям, що можуть призвести до болю та втрати зубів у майбутньому.',
        prevention:
          "Запобігання карієсу включає в себе регулярну гігієну порожнини рота, таку як чищення зубів та використання зубної нитки, обмеження споживання цукрів та кислих продуктів, а також регулярні візити до стоматолога для попереднього виявлення та лікування початкових стадій карієсу. Це допомагає підтримувати здоров'я зубів і уникнути подальших ускладнень, які можуть виникнути від карієсу.",
      },
      {
        id: 'root_canal_treatment',
        name_ua: 'Лікування кореневих каналів',
        name_en: 'Root Canal Treatment',
        description:
          'Проблема полягає в тому, що багато людей стикаються з необхідністю лікування кореневих каналів зубів через розповсюдження карієсу або інших станів, що призводять до болю та запалення в дентальному відділенні. Це створює необхідність у висококваліфікованих зубних послугах та може бути витратним та незручним для пацієнтів.',
        prevention:
          "Запобігання цій проблемі полягає в регулярному відвідуванні стоматолога для проведення профілактичних оглядів та дотриманні засобів особистої гігієни порожнини рота. Основними заходами профілактики є ретельна гігієна, включаючи чистку зубів та використання нитки для зубів, а також правильне харчування та уникання вживання шкідливих звичок, таких як куріння та надмірне споживання цукру. Регулярні консультації з лікарем також допомагають вчасно виявляти та лікувати проблеми зі здоров'ям зубів, запобігаючи подальшому розвитку кореневих каналів.",
      },
      {
        id: 'tooth_extraction',
        name_ua: 'Видалення зубів',
        name_en: 'Tooth Extraction',
        description:
          'Проблема видалення зубів полягає в тому, що ця процедура може супроводжуватися болячими відчуттями та потенційними ускладненнями під час відновлення. Додатково, видалення зубів може призвести до негативного впливу на прикус та зовнішній вигляд обличчя.',
        prevention:
          'Запобігання цій проблемі включає в себе ретельну гігієну ротової порожнини, регулярні візити до стоматолога для вчасного виявлення проблем та вчасної діагностики. Деякі ситуації можуть вимагати ортодонтічних втручань для підтримки правильного прикусу, а також дотримання рекомендацій щодо дієти та післяопераційного догляду для швидкого і безпечного відновлення пацієнта.',
      },
    ],
  },
  {
    id: 'category_2',
    category: 'Захворювання ясен та слизової оболонки порожнини рота',
    problems: [
      {
        id: 'gum_inflammation',
        name_ua: 'Запалення ясен',
        name_en: 'Gum Inflammation',
        description:
          'Проблема запалення ясен полягає в запальному процесі, який виникає в яснах навколо зубів, супроводжується кровотечею, болем і підвищеним ризиком втрати зубів. Запобігання запаленню ясен включає регулярну гігієну ротової порожнини, здорове харчування, відмову від куріння та відвідування зубного лікаря для профілактичних оглядів і чищення зубів.',
        prevention:
          "Запалення ясен може призвести до серйозних проблем зі здоров'ям ротової порожнини і загальним станом організму. Для попередження цієї проблеми важливо ретельно дотримуватися правил гігієни ротової порожнини, збалансованого харчування та регулярно відвідувати зубного лікаря для контролю і профілактики. Окрім цього, важливо уникати вживання тютюнових виробів, які можуть загострити стан ясен і сприяти розвитку запалення.",
      },
      {
        id: 'bleeding_gums',
        name_ua: 'Кровоточивість ясен',
        name_en: 'Bleeding Gums',
        description:
          "Проблема кровоточивості ясен полягає в тому, що під час чищення зубів або жевання жорсткої їжі ясна можуть починати кровотіти. Це може бути ознакою запалення ясен, відсутності належної гігієни ротової порожнини або інших проблем зі здоров'ям ясен.",
        prevention:
          "Запобігання кровоточивості ясен включає регулярну та дбайливу гігієну ротової порожнини, використання м'яких щіток для чищення зубів, відмову від агресивних методів чищення, таких як сильний тиск при чищенні або використання зубочисток, а також відвідування зубного лікаря для профілактичних оглядів і чищення зубів. Також важливо включити до свого раціону збалансовану харчову дієту, багату вітамінами та мінералами, які сприяють зміцненню ясен і загальному здоров'ю ротової порожнини.",
      },
      {
        id: 'oral_swelling',
        name_ua: 'Набряк в полості рота',
        name_en: 'Oral Swelling',
        description:
          'Проблема набряку в полості рота, яка також може вказувати на лімфаденіт, полягає в запальному процесі та збільшенні лімфатичних вузлів (лімфатичних залоз) в області шиї або щелепи. Ця проблема може виникати внаслідок інфекційних або запальних процесів у ротовій порожнині, горлі, зубах або інших структурах обличчя та шиї.',
        prevention:
          "Запобігання лімфаденіту включає в себе збереження гігієни ротової порожнини, як регулярне чищення зубів та вчасну обробку інфекцій та запальних процесів у ротовій порожнині. Якщо з'являється набряк в області рота, це може бути ознакою серйозної проблеми, і важливо негайно звернутися до лікаря для діагностики та лікування, оскільки лімфаденіт може виникати внаслідок інфекцій, які вимагають лікування антибіотиками або іншими методами.",
      },
    ],
  },
  {
    id: 'category_3',
    category: 'Лікування прикусу',
    problems: [
      {
        id: 'treatment_of_malocclusion',
        name_ua: 'Лікування неправильного прикусу',
        name_en: 'Treatment of Malocclusion',
        description:
          "Проблема полягає в поширеному виникненні неправильного прикусу у дітей та дорослих, що може призвести до функціональних та косметичних проблем зі здоров'ям ротової порожнини. Недостатня увага до цієї проблеми може призвести до серйозних стоматологічних ускладнень та психосоціальних проблем.",
        prevention:
          'Лікування неправильного прикусу включає в себе ряд методів, таких як ортодонтія, хірургічні втручання та фізіотерапія. Важливо вчасно консультуватися з лікарем-стоматологом для визначення оптимального лікування та попередження подальшого загострення проблеми.',
      },
      {
        id: 'treatment_of_bite_abnormality',
        name_ua: 'Лікування вади жування',
        name_en: 'Treatment of Bite Abnormality',
        description:
          "Проблема полягає в тому, що вада жування може призвести до ряду негативних наслідків, таких як порушення структури зубів і м'язів обличчя, що може призвести до болей і обмеження функціональності. Ця проблема особливо актуальна у дітей та підлітків, оскільки недостатньо раннього виявлення і лікування може призвести до серйозних проблем у подальшому.",
        prevention:
          'Лікування вади жування включає в себе комплексний підхід, який включає ортодонтічну корекцію, фізіотерапію та роботу з лікарем-стоматологом. Важливо вчасно виявляти цю проблему і розпочинати лікування, щоб уникнути подальших ускладнень і забезпечити правильний розвиток зубно-жувальної системи дитини.',
      },
    ],
  },
  {
    id: 'category_4',
    category: 'Щелепно-лицьова хірургія',
    problems: [
      {
        id: 'lower_jaw_prosthesis',
        name_ua: 'Протезування нижньої щелепи',
        name_en: 'Lower Jaw Prosthesis',
        description:
          "Проблема протезування нижньої щелепи полягає в складнощах, пов'язаних із забезпеченням стабільності та комфорту для пацієнта через особливості анатомії цієї області. Це може призвести до незручностей та недоліків у функціональності протезу.",
        prevention:
          'Для запобігання цій проблемі важливо враховувати індивідуальні особливості пацієнта та використовувати високоякісні матеріали та технології при виготовленні ендо-протезів. Додатково, проведення регулярних перевірок і адаптацій протезу під час носіння може допомогти досягнути оптимальної функціональності та зручності для пацієнта.',
      },
    ],
  },
  {
    id: 'category_5',
    category: 'Реставрація',
    problems: [
      {
        id: 'restoration_of_damaged_teeth',
        name_ua: 'Відновлення пошкоджених зубів',
        name_en: 'Restoration of Damaged Teeth',
        description:
          'Проблемою є відпадання зубів або їх пошкодження у людей, які не мають належних знань щодо можливих методів відновлення, таких як коронки чи пломби. Це може призвести до болю, незручностей та погіршення стану зубів, що потребують лікування.',
        prevention:
          "Для запобігання цій проблемі важливо розповідати громадянам про доступні методи відновлення пошкоджених зубів, такі як коронки чи пломби, і надавати інформацію про можливі наслідки недбалого ставлення до стану зубів. Крім того, рекомендується регулярні візити до стоматолога для профілактичних оглядів та консультацій з метою підтримання здоров'я ротової порожнини.",
      },
      {
        id: 'restorative_procedures_fillings',
        name_ua: 'Реставраційні процедури (пломби)',
        name_en: 'Restorative Procedures (Fillings)',
        description:
          'Проблемою є неефективна збереженість зубних пломб після реставраційних процедур, що може призвести до руйнування та втрати структурної цілісності зубів. Ця проблема може виникнути через недостатню адгезію пломб до зубів та незабезпечення тривалої тривкості реставрації.',
        prevention:
          'Запобігання цій проблемі включає в себе здійснення ретельного підбору матеріалів для пломбування та належну підготовку зубів перед процедурою. Додатково, важливо дотримуватися правильної техніки реставрації та регулярно контролювати стан зубів після проведення процедур реставрації для вчасного виявлення і усунення можливих проблем. Один із способів запобігання може бути також застосування сучасних технологій та матеріалів, що мають покращену тривкість та адгезію до зубів.',
      },
      {
        id: 'restorative_procedures_crowns',
        name_ua: 'Реставраційні процедури (коронки)',
        name_en: 'Restorative Procedures (Crowns)',
        description:
          'Проблема полягає в тому, що коронки, використовувані у реставраційних процедурах зубів, можуть призводити до нестачі здорової зубної тканини та інших ускладнень в майбутньому.',
        prevention:
          'Для запобігання цій проблемі, важливо обережно підходити до вибору та застосування коронок у реставраційних процедурах. Дентисти повинні враховувати індивідуальні особливості пацієнта, вибирати відповідні матеріали та методи реставрації, а також здійснювати регулярний моніторинг стану зубів, щоб уникнути подальших ускладнень та зберегти здорову зубну тканину.',
      },
    ],
  },
  {
    id: 'category_6',
    category: 'Протезування',
    problems: [
      {
        id: 'dental_implant_installation',
        name_ua: 'Встановлення зубних імплантів',
        name_en: 'Dental Implant Installation',
        description:
          'Проблема встановлення зубних імплантів полягає в тому, що ця процедура може бути дорогою та вимагає високої кваліфікації лікаря, що призводить до обмеженого доступу пацієнтів до цього виду лікування.',
        prevention:
          'Запобігання цій проблемі включає в себе розробку програм публічного страхування або фінансової підтримки для пацієнтів, а також підвищення стандартів професійної підготовки стоматологів та хірургів, що займаються імплантацією зубів. Додатково, розробка та впровадження нових технологічних методів імплантації може полегшити доступ пацієнтів до цієї процедури.',
      },
    ],
  },
  {
    id: 'category_7',
    category: 'Профілактичні процедури та інше',
    problems: [
      {
        id: 'teeth_whitening',
        name_ua: 'Відбілювання зубів',
        name_en: 'Teeth Whitening',
        description:
          'Проблема відбілювання зубів полягає в тому, що багато людей намагаються поліпшити зовнішній вигляд свого посмішки, але можуть виникнути ризики і побічні ефекти. Перш за все, багато методів відбілювання зубів можуть призвести до чутливості зубів. Під час процедур, які використовують хімічні речовини, як перекис водню, може бути пошкоджена емаль зубів, що робить їх більш чутливими до температурних змін і їжі.',
        prevention:
          "Другою проблемою є те, що результати відбілювання зубів можуть бути тимчасовими, і з часом зуби можуть знову затемнитися, особливо якщо не зберігати правильну гігієну ротової порожнини та утримуватися від продуктів, що пожовклі зуби. Також існує ризик, що під час відбілювання можуть пошкодитися якісь інші елементи стоматологічного здоров'я, такі як ясна або реставрації на зубах. Тому перед початком будь-яких процедур відбілювання зубів важливо звернутися до професіонального лікаря та врахувати можливі ризики та обмеження.",
      },
      {
        id: 'professional_hygiene',
        name_ua: 'Профілактично-професійна гігієна',
        name_en: 'Professional Hygiene',
        description:
          "Проблема полягає в недостатній увазі до здоров'я порожнини рота та зубів, що може призвести до розвитку карієсу та інших стоматологічних захворювань. Недбале ставлення до профілактично-професійної гігієни порожнини рота створює необхідні умови для збільшення ризику виникнення стоматологічних проблем.",
        prevention:
          "Профілактично-професійна гігієна (профілактична чистка) є важливим кроком у попередженні стоматологічних захворювань. Регулярні візити до стоматолога для професійного очищення зубів та навчання правильній домашній гігієні можуть допомогти уникнути багатьох проблем зі здоров'ям ротової порожнини та зубів.",
      },
      {
        id: 'plaque_and_tartar_removal',
        name_ua: 'Зняття зубних відкладень',
        name_en: 'Plaque and Tartar Removal',
        description:
          'Проблема полягає в накопиченні зубних відкладень, таких як зубний нальот і зубний камінь, на поверхні зубів, що може призвести до розвитку карієсу та захворювань ясен. Знезаразити цю проблему та запобігти її розвитку можна шляхом регулярного зняття зубних відкладень, яке включає професійну гігієну порожнини рота та правильний домашній догляд за зубами і яснами.',
        prevention:
          "Зняття зубних відкладень є ефективним методом для підтримання здоров'я порожнини рота і запобігання карієсу та захворювань ясен. Регулярні візити до стоматолога для професійного чищення та дотримання правильної гігієни ротової порожнини вдома є ключовими кроками у збереженні здорових зубів і ясен.",
      },
      {
        id: 'bad_breath',
        name_ua: 'Неприємний запах із рота',
        name_en: 'Bad Breath',
        description:
          'Проблема неприємного запаху із рота виникає при наявності бактерій у ротовій порожнині, які виділяють запахові речовини, що призводять до неприємного аромату. Ця неприємність може вплинути на самооцінку і соціальні відносини людини.',
        prevention:
          'Запобігання цій проблемі включає в себе регулярну гігієну ротової порожнини, вживання достатньої кількості води, жування цукрових безцукрових жевачок і відвідування стоматолога для вчасного лікування захворювань ротової порожнини. Крім того, важливо відмовитися від шкідливих звичок, таких як куріння і вживання алкоголю, які також можуть призводити до неприємного запаху із рота.',
      },
    ],
  },
  {
    id: 'category_8',
    category: 'Дитяча стоматологія',
    problems: [
      {
        id: 'cavities_in_baby_teeth',
        name_ua: 'Карієс молочних зубів',
        name_en: 'Cavities in Baby Teeth',
        description:
          "Карієс молочних зубів є серйозною проблемою дитячого здоров'я, що може призвести до болісних відчуттів, втрати зубів та негативно позначитися на загальному фізичному самопочутті дитини.",
        prevention:
          'Запобігання карієсу молочних зубів включає в себе регулярну гігієну ротової порожнини, вживання обмеженої кількості цукрів у їжу та напої, а також важливість раннього введення зубної гігієни в щоденну рутину дитини. Регулярні візити до стоматолога для профілактичних оглядів та обробки фтором також є ефективними заходами у запобіганні карієсу.',
      },
      {
        id: 'filling_baby_teeth_under_anesthesia',
        name_ua: 'Пломбування молочних зубів під наркозом',
        name_en: 'Filling Baby Teeth Under Anesthesia',
        description:
          'Проблема полягає в тому, що деякі діти досвідчують значних труднощів під час процедури пломбування молочних зубів через страх чи незручність, що може призвести до відкладання лікування та погіршення стану зубів.',
        prevention:
          "Запобігання цій проблемі може бути досягнуто за допомогою проведення пломбування молочних зубів під наркозом. Цей метод надає можливість дитині відчувати мінімальну дискомфорт та стрес під час процедури, що сприяє ефективному та своєчасному лікуванню. Крім того, це дозволяє лікарям здійснювати ретельний огляд та лікування, що є особливо важливим для забезпечення здоров'я дитячого ротового порожнини.",
      },
    ],
  },
];
