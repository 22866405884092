import * as React from 'react';
const IconInstagram = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
  >
    <rect width={40} height={40} fill="url(#a)" rx={7} />
    <path
      fill="#fff"
      d="M15.584 19.949a4.363 4.363 0 1 1 4.361 4.364 4.363 4.363 0 0 1-4.361-4.364Zm-2.36 0a6.721 6.721 0 1 0 13.442 0 6.721 6.721 0 1 0-13.441 0Zm12.138-6.989a1.572 1.572 0 0 0 2.68 1.112 1.571 1.571 0 0 0-1.11-2.682 1.571 1.571 0 0 0-1.57 1.57ZM14.658 30.605c-1.276-.058-1.97-.27-2.43-.45a4.069 4.069 0 0 1-1.506-.98 4.04 4.04 0 0 1-.979-1.504c-.18-.46-.392-1.155-.45-2.431-.064-1.38-.076-1.795-.076-5.29 0-3.497.013-3.91.076-5.292.058-1.276.272-1.969.45-2.43a4.07 4.07 0 0 1 .979-1.507 4.033 4.033 0 0 1 1.505-.979c.461-.18 1.155-.392 2.43-.45 1.38-.064 1.795-.076 5.288-.076 3.494 0 3.909.013 5.29.076 1.276.058 1.968.272 2.43.45a4.054 4.054 0 0 1 1.506.98c.458.457.74.894.979 1.505.18.461.392 1.155.45 2.431.063 1.382.076 1.795.076 5.291 0 3.496-.013 3.91-.076 5.29-.058 1.277-.272 1.971-.45 2.432a4.056 4.056 0 0 1-.98 1.505 4.068 4.068 0 0 1-1.505.98c-.46.179-1.154.392-2.43.45-1.38.063-1.794.076-5.29.076-3.495 0-3.908-.013-5.287-.076ZM14.55 6.936c-1.394.064-2.346.285-3.177.609a6.42 6.42 0 0 0-2.319 1.51 6.397 6.397 0 0 0-1.51 2.319c-.323.832-.544 1.784-.607 3.178-.065 1.395-.08 1.842-.08 5.397s.015 4.002.08 5.398c.063 1.393.284 2.345.608 3.177a6.4 6.4 0 0 0 1.51 2.32 6.435 6.435 0 0 0 2.318 1.51c.833.323 1.783.544 3.177.608 1.396.063 1.841.079 5.395.079 3.555 0 4.001-.015 5.396-.08 1.394-.063 2.345-.284 3.177-.608a6.435 6.435 0 0 0 2.319-1.51 6.414 6.414 0 0 0 1.51-2.319c.323-.832.545-1.784.607-3.177.064-1.397.079-1.843.079-5.398 0-3.555-.015-4.002-.079-5.398-.063-1.393-.284-2.346-.608-3.177a6.439 6.439 0 0 0-1.51-2.32 6.404 6.404 0 0 0-2.317-1.51c-.833-.323-1.784-.545-3.176-.608-1.396-.063-1.842-.079-5.396-.079-3.555 0-4.001.015-5.397.08Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={39.231}
        x2={-0.769}
        y1={40}
        y2={0}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FBE18A" />
        <stop offset={0.21} stopColor="#FCBB45" />
        <stop offset={0.38} stopColor="#F75274" />
        <stop offset={0.52} stopColor="#D53692" />
        <stop offset={0.74} stopColor="#8F39CE" />
        <stop offset={1} stopColor="#5B4FE9" />
      </linearGradient>
    </defs>
  </svg>
);
export default IconInstagram;
