import * as React from 'react';
const IconTikTok = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
  >
    <rect width={40} height={40} fill="#000" rx={7} />
    <path
      fill="#EE1D52"
      fillRule="evenodd"
      d="M25.895 16.32a10.123 10.123 0 0 0 5.907 1.875v-4.198a5.682 5.682 0 0 1-1.237-.133v3.344c-2.122.004-4.19-.66-5.914-1.896v8.634a7.855 7.855 0 0 1-4.146 6.894 7.838 7.838 0 0 1-8.031-.38 7.841 7.841 0 0 0 13.421-5.486V16.32Zm1.531-4.282a5.872 5.872 0 0 1-1.53-3.456v-.546H24.72a5.937 5.937 0 0 0 2.705 4.002ZM15.193 27.136a3.584 3.584 0 0 1 2.873-5.758c.37 0 .738.057 1.09.168v-4.338a8.243 8.243 0 0 0-1.244-.07v3.373a3.584 3.584 0 0 0-4.401 2.067 3.592 3.592 0 0 0 1.703 4.558h-.021Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M24.65 15.284a10.116 10.116 0 0 0 5.914 1.896v-3.344a5.898 5.898 0 0 1-3.139-1.798 5.93 5.93 0 0 1-2.705-4.002h-3.09v16.938a3.591 3.591 0 0 1-2.445 3.39 3.583 3.583 0 0 1-3.993-1.228 3.588 3.588 0 0 1-1.874-4.031 3.59 3.59 0 0 1 3.482-2.762c.37 0 .737.058 1.09.168v-3.373a7.832 7.832 0 0 0-7.07 4.884 7.85 7.85 0 0 0 1.653 8.438 7.839 7.839 0 0 0 11.077-2.484 7.855 7.855 0 0 0 1.12-4.03l-.02-8.662Z"
      clipRule="evenodd"
    />
    <path
      fill="#69C9D0"
      fillRule="evenodd"
      d="M30.564 13.836v-.903a5.862 5.862 0 0 1-3.138-.896 5.85 5.85 0 0 0 3.139 1.799Zm-5.843-5.8c0-.161-.05-.33-.07-.49V7h-4.264v16.946a3.584 3.584 0 0 1-5.194 3.19 3.586 3.586 0 0 0 6.438-2.162V8.035h3.09Zm-6.83 9.095v-.959a7.83 7.83 0 0 0-8.381 4.99 7.85 7.85 0 0 0 2.963 9.297 7.845 7.845 0 0 1 5.439-13.293l-.021-.035Z"
      clipRule="evenodd"
    />
  </svg>
);
export default IconTikTok;
